import { TinyEmitter } from 'tiny-emitter';

const emitter = new TinyEmitter();

export default {
  $on: emitter.on,
  $once: emitter.once,
  $off: emitter.off,
  $emit: emitter.emit,
};
