const prefix = '__LOCKER__';

interface UnserializedPayload {
  value: any;
  expiry?: number;
}

function prefixedKey(key: string): string {
  return prefix + key;
}

function isExpired(unserializedPayload: UnserializedPayload) {
  return (
    unserializedPayload['expiry'] !== undefined &&
    unserializedPayload['expiry'] < new Date().getTime()
  );
}

function isLockerKey(key: string): boolean {
  return key.indexOf(prefix) === 0;
}

class Locker {
  set(key: string, value: any, ttl: number | null = null): void {
    const unserializedPayload = { value };

    if (ttl !== null) {
      unserializedPayload['expiry'] = new Date().getTime() + ttl;
    }

    const serializedPayload = JSON.stringify(unserializedPayload);

    localStorage.setItem(prefixedKey(key), serializedPayload);
  }

  forget(key: string): void {
    localStorage.removeItem(prefixedKey(key));
  }

  get(key: string, defaultValue: any = null): any {
    const serializedPayload = localStorage.getItem(prefixedKey(key));

    if (serializedPayload !== null) {
      const unserializedPayload: UnserializedPayload =
        JSON.parse(serializedPayload);

      if (!isExpired(unserializedPayload)) {
        return unserializedPayload['value'];
      }

      this.forget(key);
    }

    return defaultValue;
  }

  flush(): void {
    Object.keys(localStorage)
      .filter((key) => isLockerKey(key))
      .forEach((prefixedKey) => {
        localStorage.removeItem(prefixedKey);
      });
  }

  flushExpired(): void {
    Object.keys(localStorage)
      .filter((key) => isLockerKey(key))
      .filter((prefixedKey) => {
        const serializedPayload = localStorage.getItem(prefixedKey);

        if (serializedPayload !== null) {
          const unserializedPayload: UnserializedPayload =
            JSON.parse(serializedPayload);

          return isExpired(unserializedPayload);
        }

        return false;
      })
      .forEach((prefixedKey) => {
        localStorage.removeItem(prefixedKey);
      });
  }
}

function createLocker(): Locker {
  return new Locker();
}

export { createLocker };
